import { BUDGET_PRICE_RANGES } from "../../../util/constants";
import { PriceRangeInfos } from "../../../util/types";

const findMatchingRange = (count: number) => (item: PriceRangeInfos) =>
  count >= item.start && count <= item.end;

export const getPriceRangeInfos = (count: number) => {
  let result: PriceRangeInfos[] = [];
  if (count < 6) {
    // ne prendre que la première tranche, soit le forfait de base pour 5 salariés
    const baseRange: PriceRangeInfos = {
      ...BUDGET_PRICE_RANGES[0],
      count: count,
    };
    result = [baseRange];
    return result;
  }
  const range = BUDGET_PRICE_RANGES.find(
    findMatchingRange(count),
  ) as PriceRangeInfos;
  result = [
    {
      ...range,
      count,
    } as PriceRangeInfos,
  ];
  return result;
};
