import { FaSortDown } from "react-icons/fa";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
import SectionTitle from "../../../components/layout/SectionTitle";
import { BUDGET_MORETOKNOW_SECTION } from "../../../util/constants";
import { classes, StyledSectionWrapper } from "./styles";

const BudgetMoreToKnow = () => {
  return (
    <StyledSectionWrapper sectionId={BUDGET_MORETOKNOW_SECTION}>
      <Container className={classes.container}>
        <SectionTitle text="Mais aussi..." variant="innoGrey" />
        <div>
          <Accordion className={classes.accordion} square={true} elevation={0}>
            <AccordionSummary
              expandIcon={<FaSortDown className={classes.expandIcon} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Assistance téléphonique</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                L'abonnement à Innovance Budget inclut l'accès illimité à notre
                assistance téléphonique 5 jours sur 7.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordion} square={true} elevation={0}>
            <AccordionSummary
              expandIcon={<FaSortDown className={classes.expandIcon} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Maintenance évolutive</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Vous bénéficiez régulièrement de mises à jour qui intègrent des
                correctifs, des évolutions ou l'ajout de nouvelles
                fonctionnalités.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </Container>
    </StyledSectionWrapper>
  );
};

export default BudgetMoreToKnow;
