import SectionWrapper from "../../../components/layout/SectionWrapper";
import styled from "../../../util/styled";

const PREFIX = "BudgetMoreToKnow";

export const classes = {
  container: `${PREFIX}-container`,
  accordion: `${PREFIX}-accordion`,
  expandIcon: `${PREFIX}-expandIcon`,
};

export const StyledSectionWrapper = styled(SectionWrapper)(({ theme }) => ({
  backgroundColor: "transparent",
  [`& .${classes.container}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.accordion}`]: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: theme.palette.ibGreen.main,
    marginBottom: theme.spacing(2),
    backgroundColor: "transparent",
  },

  [`& .${classes.expandIcon}`]: {
    color: theme.palette.ibGreen.main,
  },
}));
