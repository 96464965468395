import { Divider } from "@mui/material";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import React from "react";
import SectionTitle from "../../../components/layout/SectionTitle";
import {
  BUDGET_FEATURES_SECTION,
  BUDGET_FEATURE_IMPORT,
  BUDGET_FEATURE_FOLLOW,
  BUDGET_FEATURE_COLLABORATE,
  BUDGET_FEATURE_REPORT,
} from "../../../util/constants";
import FeatureRow from "../../../components/FeatureRow";
import { classes, StyledSectionWrapper } from "./styles";

const AgendaFeatures = () => {
  return (
    <StyledSectionWrapper sectionId={BUDGET_FEATURES_SECTION}>
      <Container className={classes.container}>
        <SectionTitle text="Fonctionnalités" variant="innoGrey" />
        <FeatureRow
          product="IB"
          id={BUDGET_FEATURE_IMPORT}
          title="Importer la comptabilité et la paie"
          text="Votre comptabilité valorisée et enrichie avec la répartition analytique des charges salariales et de l’indirect"
          imgSrc="/images/budget/innovance-solutions-budget-importer-comptabilite-paie.svg"
          imgWidth="811"
          imgHeight="696"
          bullets={[
            "Un outil de gestion parfaitement en phase avec la comptabilité de l’association, grâce à l’import des écritures des logiciels de comptabilité et de paie",
            "Compatibilité avec les logiciels de comptabilité et de paie utilisés par les associations ou les cabinets d’expertise comptable",
            "Aller plus loin que la saisie analytique comptable classique avec la ventilation automatique des coûts salariaux en fonction des temps passés par les salariés sur chaque projet ",
            "Répartition automatique des charges indirectes et des subventions de fonctionnement à l’aide de deux clés de répartition (« temps passés » ou « pourcentages »)",
          ]}
        />
        <Divider />
        <FeatureRow
          product="IB"
          id={BUDGET_FEATURE_FOLLOW}
          direction="row-reverse"
          title="Suivre le budget en temps réel"
          text="Un suivi budgétaire facilité tout au long de l’année"
          imgSrc="/images/budget/innovance-solutions-budget-suivi-budgetaire.svg"
          imgWidth="580"
          imgHeight="564"
          bullets={[
            "Construction et mémorisation du budget prévisionnel initial soumis à l’approbation de la gouvernance de l’association",
            "Faire évoluer le budget prévisionnel en créant des révisions en cours d’exercice",
            "Projection budgétaire et bilans trimestriels ou à mi-exercice facilités par la comparaison entre les versions du prévisionnel et la réalité des écritures comptables",
            "Grâce au module optionnel Innovance Engagements, comparer Prévisionnel / Engagé / Réalisé",
          ]}
        />
        <Divider />
        <FeatureRow
          product="IB"
          id={BUDGET_FEATURE_COLLABORATE}
          title="Collaborer et dialoguer"
          text="Instaurez un dialogue de gestion entre responsable d’un projet, direction et responsable administratif et financier pour un meilleur suivi budgétaire des actions"
          imgSrc="/images/budget/innovance-solutions-budget-rendre-collaborer-et-dialoguer.svg"
          imgWidth="884"
          imgHeight="478"
          bullets={[
            "Disposer en un point focal unique de l’ensemble des données budgétaires actualisées de l’association ",
            "Via un droit d’accès particulier, permettre au responsable du budget d’un projet d’accéder au détail de la saisie des écritures analytiques sans que cela nécessite un accès au logiciel de comptabilité",
            "Droits d’accès différenciés au module Budgétaire (saisie prévisionnel ou non, visualisation ou non des lignes budgétaires salariales)",
          ]}
        />
        <Divider />
        <FeatureRow
          product="IB"
          id={BUDGET_FEATURE_REPORT}
          direction="row-reverse"
          title="Rendre compte aux financeurs"
          text="Gagnez du temps pour les comptes rendus aux financeurs"
          imgSrc="/images/budget/innovance-solutions-budget-rendre-compte-aux-financeurs.svg"
          imgWidth="890"
          imgHeight="490"
          bullets={[
            "Construction de modèles de rapports pour répondre aux attendus de mise en forme des financeurs",
            "Export des données exploitables sous forme de tableaux croisés dynamiques Microsoft Excel®",
          ]}
        />
      </Container>
    </StyledSectionWrapper>
  );
};

export default AgendaFeatures;
