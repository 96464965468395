import React from "react";
import Customers from "../components/CustomerSection";
import Seo from "../components/Seo";
import BudgetCarousel from "../views/Budget/Carousel";
import BudgetFeatures from "../views/Budget/Features";
import BudgetHero from "../views/Budget/Hero";
import BudgetMoreToKnow from "../views/Budget/MoreToKnow";
import BudgetPricings from "../views/Budget/Pricing";
import { clients } from "../data";

const customers = clients.filter((c) => c.produits.includes("Budget"));

const MetronomePage = () => {
  return (
    <React.Fragment>
      <Seo title="Innovance Budget" />
      <BudgetHero />
      <BudgetFeatures />
      <BudgetCarousel />
      <BudgetMoreToKnow />
      <Customers customers={customers} />
      <BudgetPricings />
    </React.Fragment>
  );
};

export default MetronomePage;
