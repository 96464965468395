import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import React from "react";
import { MdStar } from "react-icons/md";
import { FeatureRowProps } from "./props";
import { classes, StyledGrid } from "./styles";

const FeatureRow = (props: FeatureRowProps) => {
  const {
    product,
    title,
    direction = "row",
    text,
    imgSrc,
    id,
    imgHeight,
    imgWidth,
    bullets,
    bulletRenderer,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledGrid
      id={id}
      container
      direction={direction}
      justifyContent={isMobile ? "center" : "flex-start"}
      alignItems="center"
      product={product}
    >
      <Grid container item sm={5} alignItems="center">
        <img
          width={imgWidth}
          height={imgHeight}
          className={classes.image}
          src={imgSrc}
          alt={id}
        />
      </Grid>
      <Grid container item sm={12} md={7} direction="column">
        <Typography
          variant="h5"
          component="h3"
          className={clsx(classes.title, classes.gutterBottom)}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className={classes.gutterBottom}
        >
          {text}
          {bulletRenderer ? (
            bulletRenderer()
          ) : bullets ? (
            <List dense>
              {bullets.map((bullet, index) => (
                <ListItem key={`${bullet}-${index}`}>
                  <ListItemIcon classes={{ root: classes.listItemIcon }}>
                    <MdStar />
                  </ListItemIcon>
                  <ListItemText primary={bullet} />
                </ListItem>
              ))}
            </List>
          ) : null}
        </Typography>
      </Grid>
    </StyledGrid>
  );
};

export default FeatureRow;
