import Alert from "@mui/material/Alert";
import React from "react";
import PriceCalculator from "../../../components/PriceCalculator";
import { PriceCalculatorInput } from "../../../components/PriceCalculator/tools";
import PricingSection from "../../../components/PricingSection";
import { BUDGET_PRICING_SECTION } from "../../../util/constants";
import { PriceRangeInfos } from "../../../util/types";
import { getPriceRangeInfos } from "./fp";

const renderDetailedResult = (
  ranges: PriceRangeInfos[] | null,
  input: PriceCalculatorInput,
  classes: {
    alert: string;
    bold: string;
  },
) =>
  ranges && ranges[0] ? (
    <Alert variant="outlined" severity="info" className={classes.alert}>
      Le montant de l'abonnement annuel Innovance Budget pour{" "}
      <span className={classes.bold}>
        {input.value} salarié{input.value! > 1 ? "s" : null}
      </span>{" "}
      sera de :{" "}
      <span className={classes.bold}>{ranges[0].totalPrice} € HT</span>
    </Alert>
  ) : null;

const BudgetPricings = () => (
  <PricingSection
    product="Budget"
    sectionId={BUDGET_PRICING_SECTION}
    priceFileName="innovance-solutions-fiche-tarifs.pdf"
    productFileName="innovance-budget-fiche-produit.pdf"
    CalculatorComponent={
      <PriceCalculator
        fieldLabel="Nombre de salariés"
        getPriceRangeInfos={getPriceRangeInfos}
        renderDetailedResult={renderDetailedResult}
      />
    }
  />
);

export default BudgetPricings;
